import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Tr = styled.tr`
  :hover {
    background-color: ${COLORES.ROW_HOVER};
  }

  input[type="checkbox"] {
    vertical-align: bottom;
  }
`;

export const TdButtons = styled.td`

  display:flex;

  button {
    border: none;
    border-radius: 0.1rem;
    padding: 0.2rem;
    color: ${COLORES.ACTIONS_BUTTON_FONT};
    background-color: transparent;
    cursor: pointer;
    a {
      color: ${COLORES.ACTIONS_BUTTON_FONT};
    }

    :hover {
      color: ${COLORES.ACTIONS_BUTTON_FONT_HOVER};
      background-color: ${COLORES.ACTIONS_BUTTON_BACKGROUND_HOVER};
    }
  }
`;
export const TdCheck = styled.td`

  padding:0px 10px
  
  
`;

export const circulo = styled.td`
table {
  border-collapse: collapse;
}
td{
  padding:5px;
  border:solid 1px #ccc;
  text-alig:
}
table {
  border-collapse: collapse;
}
td{
  padding:5px;
  border:solid 1px #ccc;
  text-alig:
}

span {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color:white;
  border:2px solid #000;
  margin:0px auto;
  padding:10%
}
.prioridad3{
  background-color:green;
}
.prioridad2{
  background-color:orange;
}
.prioridad1{
  background-color:red;
}
`

