import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORES.RESULT_MODULE_BACKGROUND};
  margin-bottom: 2rem;
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
  font-weight: bold;
  color: ${COLORES.RESULT_MODULE_HEADER_FONT};
  background-color: ${COLORES.RESULT_MODULE_HEADER_BACKGROUND};
  border-radius: 0.25rem 0.25rem 0 0;

  p {
    line-height: 2rem;
    padding: 0 1rem;
  }

  button {
    padding: 0 1rem;
    border: none;
    color: ${COLORES.RESULT_MODULE_HEADER_FONT};
    background-color: transparent;
    cursor: pointer;

    :hover {
      color: ${COLORES.RESULT_MODULE_HEADER_FONT_HOVER};
    }

    :disabled {
      color: ${COLORES.RESULT_MODULE_HEADER_FONT_DISABLED};
      cursor: default;
    }
  }
`;

export const Content = styled.div`
  overflow-x: scroll;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        height: 1.5rem;
      }
    }

    tbody {
      td {
        text-align: center;
      }
    }
  }
`;

export const TrMassiveReAsign = styled.tr`
  background-color: ${COLORES.EXTRA_HEADER_BACKGROUND};
  height: 2rem;

  th {
    text-align: start;
  }

  p {
    display: inline;
    color: ${COLORES.EXTRA_HEADER_FONT};
    font-weight: bold;
    padding-left: 1rem;
  }

  input {
    margin-left: 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }

  button {
    margin-left: 0.5rem;
    padding: 0.2rem;
    border: none;
    color: ${COLORES.EXTRA_HEADER_FONT};
    background-color: transparent;
    cursor: pointer;

    :hover {
      color: ${COLORES.EXTRA_HEADER_HOVER};
    }

    :disabled {
      color: ${COLORES.EXTRA_HEADER_DISABLED};
      cursor: default;
    }
  }
`;

export const TrColumnTitles = styled.tr`
  background-color: ${COLORES.HEADER_ROW_BACKGROUND};

  // th:nth-child(1),
  // th:nth-child(2) {
  //   width: 2%;
  // }
  // th:nth-child(3),
  // th:nth-child(4),
  // th:nth-child(5),
  // th:nth-child(6),
  // th:nth-child(7),
  // th:nth-child(8),
  // th:nth-child(9),
  // th:nth-child(13),
  // th:nth-child(14) {
  //   width: 8%;
  // }
  // th:nth-child(10),
  // th:nth-child(11) {
  //   width: 10%;
  // }
  // th:nth-child(12) {
  //   width: 15%;
  // }
`;

export const ButtonTh = styled.th`
  button {
    border: none;
    border-radius: 0.2rem;
    margin-left: 0.25rem;
    cursor: pointer;
    background-color: ${COLORES.SORT_BUTTON_BACKGROUND};
    background-color: ${(props) => props.active && COLORES.SORT_BUTTON_ACTIVE};

    :hover {
      background-color: ${COLORES.SORT_BUTTON_HOVER};
    }

    :disabled {
      cursor: default;
      background-color: transparent;
    }
  }
`;

export const TdFoot = styled.td`
  p,
  button {
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    margin: 0.5rem;
  }
  button {
    cursor: pointer;
  }
`;

