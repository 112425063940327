import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { BuscadorTareas } from "../pages/BuscadorTareas";
import { FichaReserva } from "../pages/FichaReserva";
import { TareaAnalisis } from "../pages/TareaAnalisis";
import { Carga } from "../pages/Carga";
import { TareaReclamacion } from "../pages/TareaReclamacion";
import { TareaRevisionOP } from "../pages/TareaRevisionOP";
import { BuscadorReservas } from "../pages/BuscadorReservas";
import { BuscadorNotarios } from "../pages/BuscadorNotarios";
import { EdicionCreacionNotarios } from "../pages/EdicionCreacionNotarios";
import { BuscadorUsuarios } from "../pages/BuscadorUsuarios";
import { ConfirmacionAsistenciaFirma } from "../pages/ConfirmacionAsistenciaFirma";
import { TipoFiscalidad } from "../pages/TipoFiscalidad";
import { MinutaSSJJ } from "../pages/MinutaSSJJ";
import { ConfirmacionFirma } from "../pages/ConfirmacionFirma";
import { ReporteGestoria } from "../pages/ReporteGestoria";
import { Autorizacion } from "../pages/Autorizacion";
import { BuscadorRoles } from "../pages/BuscadorRoles";
import { EdicionCreacionUsuarios } from "../pages/EdicionCreacionUsuarios";
import { EdicionCreacionRoles } from "../pages/EdicionCreacionRoles";
import { ErrorPage } from "../pages/ErrorPage";
import { BuscadorAsignacionGestoria } from "../pages/BuscadorAsignacionGestoria";
import { AsignacionGestoria } from "../pages/AsignacionGestoria";
import { BuscadorGestoriaProvincia } from "../pages/BuscadorGestoriaProvincia";
import { ProtectedRoute } from "./protected";

export const Paths = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/BuscadorTareas" replace />} />
      <Route
        path="/BuscadorTareas"
        element={
          <ProtectedRoute permit="BuscadorTareas" redirectPath="/noAccess">
            <BuscadorTareas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/FichaReserva/:idReserva"
        element={
          <ProtectedRoute permit="FichaReserva" redirectPath="/noAccess">
            <FichaReserva />
          </ProtectedRoute>
        }
      />
      <Route
        path="/TareaAnalisis/:idTarea"
        element={
          <ProtectedRoute permit="TareaAnalisis" redirectPath="/noAccess">
            <TareaAnalisis />
          </ProtectedRoute>
        }
      />
      <Route path="/Carga/:idCarga" element={<Carga />} />
      <Route
        path="/ReclamacionTecnico/:idTarea"
        element={
          <ProtectedRoute permit="TareaAnalisis" redirectPath="/noAccess">
            <TareaReclamacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ReclamacionComunidades/:idTarea"
        element={
          <ProtectedRoute
            permit="ReclamacionComunidades"
            redirectPath="/noAccess"
          >
            <TareaReclamacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ReclamacionVPO/:idTarea"
        element={
          <ProtectedRoute permit="ReclamacionVPO" redirectPath="/noAccess">
            <TareaReclamacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ReclamacionImpuestos/:idTarea"
        element={
          <ProtectedRoute
            permit="ReclamacionImpuestos"
            redirectPath="/noAccess"
          >
            <TareaReclamacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ReclamacionOperadorBloqueDocumentalAdmision/:idTarea"
        element={
          <ProtectedRoute
            permit="ReclamacionOperadorBloqueDocumentalAdmision"
            redirectPath="/noAccess"
          >
            <TareaReclamacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ReclamacionNotasSimples/:idTarea"
        element={
          <ProtectedRoute
            permit="ReclamacionNotasSimples"
            redirectPath="/noAccess"
          >
            <TareaReclamacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ReclamacionTomasPosesion/:idTarea"
        element={
          <ProtectedRoute
            permit="ReclamacionTomasPosesion"
            redirectPath="/noAccess"
          >
            <TareaReclamacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/RevisionOP/:idTarea"
        element={
          <ProtectedRoute permit="RevisionOP" redirectPath="/noAccess">
            <TareaRevisionOP />
          </ProtectedRoute>
        }
      />
      <Route
        path="/BuscadorReservas"
        element={
          <ProtectedRoute permit="BuscadorReservas" redirectPath="/noAccess">
            <BuscadorReservas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ConfirmacionAsistenciaFirma/:idTarea"
        element={
          <ProtectedRoute
            permit="ConfirmacionAsistenciaFirma"
            redirectPath="/noAccess"
          >
            <ConfirmacionAsistenciaFirma />
          </ProtectedRoute>
        }
      />
      <Route
        path="/TipoFiscalidad/:idTarea"
        element={
          <ProtectedRoute permit="TipoFiscalidad" redirectPath="/noAccess">
            <TipoFiscalidad />
          </ProtectedRoute>
        }
      />
      <Route
        path="/MinutaSSJJ/:idTarea"
        element={
          <ProtectedRoute permit="MinutaSSJJ" redirectPath="/noAccess">
            <MinutaSSJJ />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ConfirmacionFirma/:idTarea"
        element={
          <ProtectedRoute permit="ConfirmacionFirma" redirectPath="/noAccess">
            <ConfirmacionFirma />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ReporteGestoria/:idTarea"
        element={
          <ProtectedRoute permit="ReporteGestoria" redirectPath="/noAccess">
            <ReporteGestoria />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Autorizacion/:idRevisionOP"
        element={
          <ProtectedRoute permit="RevisionOP" redirectPath="/noAccess">
            <Autorizacion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/Notario/:idNotario"
        element={
          <ProtectedRoute
            permit="EdicionCreacionNotarios"
            redirectPath="/noAccess"
          >
            <EdicionCreacionNotarios />
          </ProtectedRoute>
        }
      />
      <Route
        path="/BuscadorNotarios"
        element={
          <ProtectedRoute permit="BuscadorNotarios" redirectPath="/noAccess">
            <BuscadorNotarios />
          </ProtectedRoute>
        }
      />
      <Route
        path="/BuscadorAsignacionGestoria"
        element={
          <ProtectedRoute
            permit="BuscadorAsignacionGestoria"
            redirectPath="/noAccess"
          >
            <BuscadorAsignacionGestoria />
          </ProtectedRoute>
        }
      />
      <Route
        path="/AsignacionGestoria/:idReserva"
        element={
          <ProtectedRoute permit="AsignacionGestoria" redirectPath="/noAccess">
            <AsignacionGestoria />
          </ProtectedRoute>
        }
      />
      <Route
        path="/BuscadorGestoriaProvincia"
        element={
          <ProtectedRoute
            permit="BuscadorGestoriaProvincia"
            redirectPath="/noAccess"
          >
            <BuscadorGestoriaProvincia />
          </ProtectedRoute>
        }
      />
      <Route path="/Rol/:idRol" element={<EdicionCreacionRoles />} />
      <Route path="/BuscadorRoles" element={<BuscadorRoles />} />
      <Route path="/Usuario/:idUsuario" element={<EdicionCreacionUsuarios />} />
      <Route path="/BuscadorUsuarios" element={<BuscadorUsuarios />} />
      <Route
        path="/noAccess"
        element={
          <ErrorPage
            type="401"
            message="No tiene acceso a esta aplicación. Contacte con el administrador"
          />
        }
      />
      <Route
        path="/notFound"
        element={<ErrorPage type="404" message="Recurso no encontrado" />}
      />
      <Route
        path="*"
        element={<ErrorPage type="400" message="Página no encontrada" />}
      />
    </Routes>
  );
};
