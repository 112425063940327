const { REACT_APP_BAR_COLOR } = process.env;

// MENU PRINCIPAL
export const MAIN_MENU = [
  {
    label: "Buscador Tareas",
    path: "BuscadorTareas",
  },
  {
    label: "Buscador Reservas",
    path: "BuscadorReservas",
  },
  {
    label: "Buscador Notarios",
    path: "BuscadorNotarios",
  },
  {
    label: "Asignacion Gestoria",
    path: "BuscadorAsignacionGestoria",
  },
];

// BUSCADOR TAREAS
export const TAREAS_BY_PAGE = 10;
export const METADATA_SEARCH_TAREAS = [
  {
    label: "ID de la reserva",
    name: "reserva",
    type: "text",
  },
  {
    label: "Tarea",
    name: "tipoTarea",
    type: "list",
    list: "TipoTarea",
  },
  {
    label: "Gestoría Tarea",
    name: "gestoriaTarea",
    type: "list",
    list: "GestoriaTarea",
  },
  {
    label: "Gestoría Reserva",
    name: "gestoriaReserva",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "CCAA",
    name: "comunidadAutonoma",
    type: "multiSelectList",
    list: "ComunidadAutonoma",
  },
  {
    label: "Provincia",
    name: "provincia",
    type: "multiSelectList",
    list: "Provincia",
  },
  {
    label: "Estado",
    name: "estado",
    type: "list",
    list: "TareaEstado",
  },
  {
    label: "Prioridad",
    name: "prioridad",
    type: "list",
    list: "Prioridad",
  },
  {
    label: "Usuario",
    name: "usuarios",
    type: "multiSelectList",
    list: "Usuario",
  },
  {
    label: "Nº de PRINEX",
    name: "numPrinex",
    type: "text",
  },
  {
    label: "Cartera",
    name: "idCarteras",
    type: "multiSelectList",
    list: "Cartera",
  },
  {
    label: "Subcartera Beagle",
    name: "subCarteraBeagle",
    type: "list",
    list: "SubCartera",
  },
  {
    label: "F. creación desde",
    name: "fechaCreacionDesde",
    type: "date",
  },
  {
    label: "F. creación hasta",
    name: "fechaCreacionHasta",
    type: "date",
  },
  {
    type: "groupListNumber",
    name: "_group",
    fields: [
      {
        label: "Importe",
        name: "tipoBusquedaImporte",
        type: "list",
        list: "TipoBusquedaImporte",
      },
      {
        label: "",
        name: "importe",
        type: "number",
        step: 1000,
        min: 0,
      },
    ],
  },
  {
    label: "Promociones",
    name: "promociones",
    type: "text",
  },
  {
    label: "Nº PRINEX LARGO",
    name: "prinexLargo",
    type: "text",
  },
  {
    label: "OP con cambios",
    name: "opConCambios",
    type: "list",
    list: "OpConCambios",
  },
  {
    label: "Prioridad firma",
    name: "prioridadFirma",
    type: "list",
    list: "PrioridadFirma",
  },
  {
    label: "Gestión Escrituración",
    name: "gestEscr",
    type: "list",
    list: "GestionEscrituracion",
  },
  {
    label: "Campaña",
    name: "campana",
    type: "text",
  },
];
export const METADATA_RESULT_TAREAS = [
  {
    label: "Notificaciones",
    name: "notificaciones",
  },
  {
    label: "OP",
    name: "reserva",
  },
  {
    label: "ActivoPrincipal",
    name: "numExpediente",
  },
  {
    label: "Cartera",
    name: "cartera",
  },
  {
    label: "Subcartera Beagle",
    name: "subCarteraBeagle",
  },
  {
    label: "Estado",
    name: "estado",
  },
  {
    label: "F. Asignación",
    name: "fecAsignacion",
  },
  {
    label: "F. Creación",
    name: "fecCreacion",
  },
  {
    label: "F. Prevista",
    name: "fecPrevista",
  },
  {
    label: "Prioridad",
    name: "prioridad",
  },
  {
    label: "Importe",
    name: "importe",
  },
  {
    label: "Tarea",
    name: "tarea",
  },
  {
    label: "Gestoría tarea",
    name: "gestoriaTarea",
  },
  {
    label: "CCAA",
    name: "comunidadAutonoma",
  },
  {
    label: "Provincia",
    name: "provincia",
  },
  {
    label: "Usuario",
    name: "usuario",
  },
  {
    label: "Promociones",
    name: "promociones",
  },
  {
    label: "Gestión Escrituración",
    name: "gestEscr",
  },
  {
    label: "Campaña",
    name: "campana",
  },
  {
    label: "Financiación",
    name: "tipoFinanciacion",
  },
];

// BUSCADOR RESERVAS
export const RESERVAS_BY_PAGE = 5;
export const METADATA_SEARCH_RESERVAS = [
  {
    label: "ID de la reserva",
    name: "reserva",
    type: "text",
  },
  {
    label: "Nº de PRINEX",
    name: "numPrinex",
    type: "number",
  },
  {
    label: "Gestoría BD",
    name: "gestoria",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "Gestoría formalización",
    name: "gestoriaFormalizacion",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "Gestoría Asistente firma",
    name: "gestoriaAsistenteFirma",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "CCAA",
    name: "comunidadAutonoma",
    type: "multiSelectList",
    list: "ComunidadAutonoma",
  },
  {
    label: "F. creación desde",
    name: "fechaCreacionDesde",
    type: "date",
  },
  {
    label: "F. creación hasta",
    name: "fechaCreacionHasta",
    type: "date",
  },
  {
    label: "F. prev. firma desde",
    name: "fechaPrevistaDesde",
    type: "date",
  },
  {
    label: "F. prev. firma hasta",
    name: "fechaPrevistaHasta",
    type: "date",
  },
  {
    label: "F. Firma Operación desde",
    name: "fechaConfirmDesde",
    type: "date",
  },
  {
    label: "F. Firma Operación hasta",
    name: "fechaConfirmHasta",
    type: "date",
  },
  {
    label: "Cartera",
    name: "idCarteras",
    type: "multiSelectList",
    list: "Cartera",
  },
  {
    label: "Subcartera Beagle",
    name: "subcarteraBeagle",
    type: "list",
    list: "SubCartera",
  },
  {
    label: "Búsqueda a nivel de",
    name: "tipoBusqueda",
    type: "list",
    list: "TipoBusqueda",
  },
  {
    label: "Promociones",
    name: "promociones",
    type: "text",
  },
  {
    label: "Nº PRINEX LARGO",
    name: "prinexLargo",
    type: "text",
  },
  {
    label: "OP con cambios",
    name: "opConCambios",
    type: "list",
    list: "OpConCambios",
  },
  {
    label: "Prioridad firma",
    name: "prioridadFirma",
    type: "list",
    list: "PrioridadFirma",
  },
  {
    label: "Gestión Escrituración",
    name: "gestEscr",
    type: "list",
    list: "GestionEscrituracion",
  },
  {
    label: "Campaña",
    name: "campana",
    type: "text",
  },
];
export const METADATA_RESULT_RESERVAS_RESERVA = [
  {
    label: "OP",
    name: "reserva",
  },
  {
    label: "Cartera",
    name: "cartera",
  },
  {
    label: "Subcartera Beagle",
    name: "subCarteraBeagle",
  },
  {
    label: "F. Creación",
    name: "fecCreacion",
  },
  {
    label: "Importe",
    name: "importe",
  },
  {
    label: "Razón",
    name: "razon",
  },
  {
    label: "Del. Territorial",
    name: "territorial",
  },
  {
    label: "Nº Act.",
    name: "numActivos",
  },
  {
    label: "Estado Com.",
    name: "estadoComercial",
  },
  {
    label: "F. Prevista",
    name: "fecPrevista",
  },
  {
    label: "F. Firma",
    name: "fecFirma",
  },
  {
    label: "Financiacion",
    name: "financiado",
  },
  {
    label: "TipoFinanciacion",
    name: "tipoFinanciacion",
  },
  {
    label: "Gestoría BD",
    name: "gestoria",
  },
  {
    label: "Gestoría formalización",
    name: "gestoriaFormalizacion",
  },
  {
    label: "Gestoría Asistente firma",
    name: "gestoriaAsistenteFirma",
  },
  {
    label: "C. Asist. Firma",
    name: "cAsistenciaFirma",
  },
  {
    label: "OK PBC",
    name: "pbc",
  },
  {
    label: "VB Form",
    name: "vistoBueno",
  },
  {
    label: "VB SSJJ",
    name: "vistoBuenoSSJJ",
  },
  {
    label: "Venta",
    name: "venta",
  },
  {
    label: "Promociones",
    name: "promociones",
  },
  {
    label: "Gestión Escrituración",
    name: "gestEscr",
  },
  {
    label: "Campaña",
    name: "campana",
  }
];

export const METADATA_RESULT_RESERVAS_ACTIVO = [
  {
    label: "OP",
    name: "reserva",
  },
  {
    label: "N. Prinex",
    name: "numPrinex",
  },
  {
    label: "Cartera",
    name: "cartera",
  },
  {
    label: "Subcartera Beagle",
    name: "subCarteraBeagle",
  },
  {
    label: "F. Creación",
    name: "fecCreacion",
  },
  {
    label: "Importe",
    name: "importe",
  },
  {
    label: "Razón",
    name: "razon",
  },
  {
    label: "Del. Territorial",
    name: "territorial",
  },
  {
    label: "Nº Act.",
    name: "numActivos",
  },
  {
    label: "Estado Com.",
    name: "estadoComercial",
  },
  {
    label: "F. Prevista",
    name: "fecPrevista",
  },
  {
    label: "F. Firma",
    name: "fecFirma",
  },
  {
    label: "Financiacion",
    name: "financiado",
  },
  {
    label: "TipoFinanciacion",
    name: "tipoFinanciacion",
  },
  {
    label: "Gestoría BD",
    name: "gestoria",
  },
  {
    label: "Gestoría formalización",
    name: "gestoriaFormalizacion",
  },
  {
    label: "Gestoría Asistente firma",
    name: "gestoriaAsistenteFirma",
  },
  {
    label: "C. Asist. Firma",
    name: "cAsistenciaFirma",
  },
  {
    label: "OK PBC",
    name: "pbc",
  },
  {
    label: "VB Form",
    name: "vistoBueno",
  },
  {
    label: "VB SSJJ",
    name: "vistoBuenoSSJJ",
  },
  {
    label: "Venta",
    name: "venta",
  },
  {
    label: "Promociones",
    name: "promociones",
  },
  {
    label: "Gestión Escrituración",
    name: "gestEscr",
  },
  {
    label: "Campaña",
    name: "campana",
  }
];

// BUSCADOR NOTARIOS
export const NOTARIOS_BY_PAGE = 10;
export const METADATA_SEARCH_NOTARIOS = [
  {
    label: "Provincia",
    name: "provincia",
    type: "list",
    list: "Provincia",
  },
  {
    label: "Nombre Notario",
    name: "nombreNotario",
    type: "text",
  },
];
export const METADATA_RESULT_NOTARIOS = [
  {
    label: "Nombre",
    name: "nombre",
  },
  {
    label: "Provincia",
    name: "nombreProvincia",
  },
  {
    label: "Localidad",
    name: "localidad",
  },
  {
    label: "Dirección",
    name: "direccion",
  },
  {
    label: "Teléfono",
    name: "telefono",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Dni",
    name: "dni",
  },
];

// EDICION CREACIÓN NOTARIO

export const METADATA_DATOS_NOTARIO = [
  {
    label: "Provincia",
    name: "provincia",
    type: "list",
    list: "Provincia",
  },
  {
    label: "Localidad",
    name: "localidad",
    type: "text",
  },
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
  },
  {
    label: "Número Colegiado",
    name: "numColegiado",
    type: "text",
  },
  {
    label: "DNI",
    name: "dni",
    type: "text",
  },
  {
    label: "Dirección",
    name: "direccion",
    type: "text",
  },
  {
    label: "Teléfono",
    name: "telefono",
    type: "text",
  },
  {
    label: "Fax",
    name: "fax",
    type: "text",
  },
  {
    label: "Email Principal",
    name: "emailPrincipal",
    type: "text",
  },
  {
    label: "Email Secundario",
    name: "emailSecundario",
    type: "text",
  },
  {
    label: "Código Postal",
    name: "codPostal",
    type: "text",
  },
  {
    label: "IBAN",
    name: "iban",
    type: "text",
  },
  {
    label: "Cuenta Corriente",
    name: "cuentaCorriente",
    type: "text",
  },
  {
    label: "Observaciones",
    name: "observaciones",
    type: "text",
  },
];

// BUSCADOR GESTORIAS
export const BUSCADOR_RESERVAGESTORIA_BY_PAGE = 10;
export const METADATA_SEARCH_BUSCADOR_RESERVAGESTORIA = [
  {
    label: "ID de la reserva",
    name: "reserva",
    type: "text",
  },
  {
    label: "Nº de PRINEX",
    name: "numPrinex",
    type: "number",
  },
  {
    label: "Estado Reserva",
    name: "estadoReserva",
    type: "multiSelectList",
    list: "EstadoReserva",
  },
  {
    label: "Gestoría BD",
    name: "gestoria",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "Gestoría formalización",
    name: "gestoriaFormalizacion",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "Gestoría Asistente firma",
    name: "gestoriaAsistenteFirma",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "CCAA",
    name: "comunidadAutonoma",
    type: "multiSelectList",
    list: "ComunidadAutonoma",
  },
  {
    label: "Provincia",
    name: "provincia",
    type: "multiSelectList",
    list: "Provincia",
  },
  {
    label: "F. creación desde",
    name: "fechaCreacionDesde",
    type: "date",
  },
  {
    label: "F. creación hasta",
    name: "fechaCreacionHasta",
    type: "date",
  },
  {
    label: "F. prev. firma desde",
    name: "fechaPrevistaDesde",
    type: "date",
  },
  {
    label: "F. prev. firma hasta",
    name: "fechaPrevistaHasta",
    type: "date",
  },
  {
    label: "F. Firma Operación desde",
    name: "fechaConfirmDesde",
    type: "date",
  },
  {
    label: "F. Firma Operación hasta",
    name: "fechaConfirmHasta",
    type: "date",
  },
  {
    label: "Cartera",
    name: "idCarteras",
    type: "multiSelectList",
    list: "Cartera",
  },
];
export const METADATA_RESULT_BUSCADOR_RESERVAGESTORIA = [
  {
    label: "OP",
    name: "reserva",
  },
  {
    label: "Cartera",
    name: "cartera",
  },
  {
    label: "F. Creación",
    name: "fecCreacion",
  },
  {
    label: "Importe",
    name: "importe",
  },
  {
    label: "Razón",
    name: "razon",
  },
  {
    label: "Del. Territorial",
    name: "territorial",
  },
  {
    label: "Nº Act.",
    name: "numActivos",
  },
  {
    label: "Estado Com.",
    name: "estadoComercial",
  },
  {
    label: "F. Prevista",
    name: "fecPrevista",
  },
  {
    label: "F. Firma",
    name: "fecFirma",
  },
  {
    label: "Gestoría BD",
    name: "gestoria",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "Gestoría formalización",
    name: "gestoriaFormalizacion",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "Gestoría Asistente firma",
    name: "gestoriaAsistenteFirma",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "CCAA",
    name: "comunidadAutonoma",
  },
  {
    label: "C. Asist. Firma",
    name: "cAsistenciaFirma",
  },
  {
    label: "Venta",
    name: "venta",
  },
  {
    label: "Promociones",
    name: "promociones",
    type: "text",
  },
];

export const RESERVAGESTORIA_BY_PAGE = 50;
export const METADATA_SEARCH_RESERVAGESTORIA = [
  {
    label: "Id de la Reserva",
    name: "codReserva",
    type: "readOnly",
  },
  {
    label: "Añadir Gestoria / Tarea a reserva",
    colSpan: "1 / 7",
    type: "separator",
    name: "separator",
  },
  {
    label: "BloqueTarea",
    name: "bloqueTarea",
    type: "multiSelectList",
    list: "BloqueTarea",
  },
  {
    label: "Tarea",
    name: "TipoTareaGestoria",
    type: "multiSelectList",
    list: "TipoTareaGestoria",
  },
  {
    label: "Gestoria",
    name: "gestoria",
    type: "list",
    list: "Gestoria",
  },
];
export const METADATA_RESULT_RESERVAGESTORIA = [
  {
    label: "Tarea",
    name: "tipoTarea",
  },
  {
    label: "Gestoria",
    name: "gestoria",
  },
];
//BUSCADOR GESTORIA PROVINCIA
export const GESTORIAPROVINCIA_BY_PAGE = 100000;
export const METADATA_SEARCH_GESTORIAPROVINCIA = [
  {
    label: "CCAA",
    name: "ComunidadesAutonomas",
    type: "multiSelectList",
    list: "ComunidadAutonoma",
  },
  {
    label: "Provincia",
    name: "provincias",
    type: "multiSelectList",
    list: "Provincia",
  },
  {
    label: "BloqueTarea",
    name: "bloque",
    type: "multiSelectList",
    list: "BloqueTarea",
  },
  {
    label: "Tarea",
    name: "tiposTareas",
    type: "multiSelectList",
    list: "TipoTareaGestoria",
  },
  {
    label: "Gestoria",
    name: "gestorias",
    type: "multiSelectList",
    list: "Gestoria",
  },
  {
    label: "Carteras",
    name: "carteras",
    type: "multiSelectList",
    list: "Cartera",
  },
];
export const METADATA_RESULT_GESTORIAPROVINCIA = [
  {
    label: "Cartera",
    name: "cartera",
  },
  {
    label: "Comunidad",
    name: "comunidad",
  },
  {
    label: "Provincia",
    name: "provincia",
  },
  {
    label: "Tarea",
    name: "tipoTarea",
  },
  {
    label: "Gestoria",
    name: "gestoria",
  },
];

// BUSCADOR USUARIOS
export const USUARIOS_BY_PAGE = 10;
export const METADATA_SEARCH_USUARIOS = [
  {
    label: "Buscar en usuarios",
    name: "nombre",
    type: "text",
  },
];
export const METADATA_RESULT_USUARIOS = [
  {
    label: "Nombre",
    name: "nombre",
  },
  {
    label: "Apellido 1",
    name: "apellido1",
  },
  {
    label: "Apellido 2",
    name: "apellido2",
  },
  {
    label: "Nombre Usuario",
    name: "nombreUsuario",
  },
  {
    label: "Alias",
    name: "alias",
  },
  {
    label: "Último Acceso",
    name: "ultimoAcceso",
  },
  {
    label: "Activo",
    name: "activo",
  },
  {
    label: "Buzón Correo",
    name: "buzonCorreo",
  },
];

// EDICION CREACIÓN USUARIO

export const METADATA_DATOS_USUARIO = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
  },
  {
    label: "Apellido 1",
    name: "apellido1",
    type: "text",
  },
  {
    label: "Apellido 2",
    name: "apellido2",
    type: "text",
  },
  {
    label: "Buzón Correo",
    name: "buzonCorreo",
    type: "text",
  },
  {
    label: "Nombre Usuario",
    name: "nombreUsuario",
    type: "readOnly",
  },
  {
    label: "Último Acceso",
    name: "ultimoAcceso",
    type: "readOnly",
  },
  {
    label: "Carteras",
    name: "carteras",
    type: "multiSelectList",
    list: "CarteraEdicionUsuarios",
  },
  {
    label: "Gestoría",
    name: "gestoria",
    type: "list",
    list: "Gestoria",
  },
  {
    label: "Roles",
    name: "roles",
    type: "multiSelectList",
    list: "Rol",
  },
  {
    label: "Activo",
    name: "activo",
    type: "checkbox",
  },
];

// BUSCADOR ROLES

export const ROLES_BY_PAGE = 10;
export const METADATA_SEARCH_ROLES = [
  {
    label: "Nombre Rol",
    name: "nombre",
    type: "text",
  },
];
export const METADATA_RESULT_ROLES = [
  {
    label: "Nombre",
    name: "nombre",
  },
  {
    label: "Descripción",
    name: "descripcion",
  },
  {
    label: "Fecha Creación",
    name: "fechaCreacion",
  },
];

// EDICION CREACIÓN ROL

export const METADATA_DATOS_ROL = [
  {
    label: "Nombre",
    name: "nombre",
    type: "text",
  },
  {
    label: "Descripcion",
    name: "descripcion",
    type: "textarea",
  },
];
export const METADATA_AUDITORIA_CAMPOS = [
  {
    label: "Campo",
    name: "campo",
  },
  {
    label: "Valor Previo",
    name: "valorAnterior",
  },
  {
    label: "Valor Nuevo",
    name: "valorNuevo",
  },
  {
    label: "Fecha Modificacion",
    name: "fecModificacion",
  },
  {
    label: "Ocultar",
    name: "ocultar",
    type: "button",
  },
  {
    label: "Eliminar",
    name: "eliminar",
    type: "button",
  },
];
export const METADATA_AUDITORIA_CAMPOS_FICHA = [
  {
    label: "Campo",
    name: "campo",
  },
  {
    label: "Valor Previo",
    name: "valorAnterior",
  },
  {
    label: "Valor Nuevo",
    name: "valorNuevo",
  },
  {
    label: "Fecha Modificacion",
    name: "fecModificacion",
  },
];
// TAREA ANÁLISIS
export const METADATA_TAREA_ANALISIS_DATOS_GENERALES = [
  {
    label: "ID de la Reserva",
    name: "codReserva",
  },
  { label: "VAI Colabora", name: "vaiColabora" },
  {
    label: "Código Oferta",
    name: "codigoOferta",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Fecha de creación",
    name: "fechaCreacion",
  },
  {
    label: "Importe total",
    name: "importeTotal",
  },
  {
    label: "Gestoría",
    name: "gestoria",
  },
  {
    label: "Nombre/Razón Social",
    name: "nombreRazonSocial",
  },
  {
    label: "DNI Titular",
    name: "dniTitular",
  },
  {
    label: "Nº Compradores",
    name: "numeroCompradores",
  },
  {
    label: "Inicio bloque doc",
    name: "inicioBloqueDoc",
  },
  {
    label: "Fin bloque doc",
    name: "finBloqueDoc",
  },
  {
    label: "Est. comercial operación",
    name: "estadoComercialOperacion",
  },

  {
    label: "Fecha notificación Tanteo CCAA",
    name: "fechaNotificacionTanteoCcaa",
  },
  {
    label: "Fecha recpción respuesta tanteo CCAA",
    name: "fechaRecepcionRespuestaTanteoCcaa",
  },
  { label: "Resultado Tanteo CCAA", name: "resultadoTanteoCcaa" },
  {
    label: "Fecha Notificación Tanteo Ayuntamiento",
    name: "fechaNotificacionTanteoAyuntamiento",
  },
  {
    label: "Fecha Recepción Respuesta Tanteo Ayuntamiento",
    name: "fechaRecepcionRespuestaTanteoAyuntamiento",
  },
  {
    label: "Resultado Tanteo Ayuntamiento",
    name: "resultadoTanteoAyuntamiento",
  },
];

export const METADATA_CAMPOS_OPCIONALES_DATOS_GENERALES = [
  "resultadoTanteoAyuntamiento",
  "fechaRecepcionRespuestaTanteoAyuntamiento",
  "fechaNotificacionTanteoAyuntamiento",
];
export const METADATA_DOCS_RESERVA = [
  {
    label: "Tipo",
    name: "tipo",
  },
  {
    label: "Enlace",
    name: "nombreDocumento",
    type: "file",
  },
  {
    label: "F. recepción",
    name: "fechaRecepcion",
  },
];
export const METADATA_DOCS_ACTIVOS = [
  {
    label: "Tipo",
    name: "tipo",
  },
  {
    label: "Enlace",
    name: "nombreDocumento",
    type: "file",
  },
  {
    label: "F. recepción",
    name: "fechaRecepcion",
  },
  {
    label: "F. reclamación",
    name: "fechaReclamacion",
  },
  {
    label: "F. previsión",
    name: "fechaPrevisionM",
  },
  {
    label: "Rev",
    name: "revision",
  },
  {
    label: "Motivo",
    name: "tipoIncidencia",
  },
  {
    label: "Estado reclamación",
    name: "estadoReclamacion",
  },
  {
    label: "EsFastrack",
    name: "esFastrack",
  },
  {
    label: "Observaciones",
    name: "observaciones",
  },
];
export const METADATA_REV_CANCELACION = [
  {
    label: "Nº PRINEX",
    name: "codActivo",
  },
  {
    label: "R. Cargas",
    name: "cargas",
  },
  {
    label: "Subtipo",
    name: "subTipoCargas",
  },
  {
    label: "Fecha Creación Tarea",
    name: "fecCreacion",
  },
  {
    label: "F. Previsión",
    name: "fecPrevision",
  },
  {
    label: "Estado",
    name: "estado",
  },
];
export const METADATA_REV_TITULARIDAD = [
  {
    label: "Nº PRINEX",
    name: "codActivo",
  },
  {
    label: "R. Titularidad",
    name: "titularidad",
  },
  {
    label: "Fecha Creación Tarea",
    name: "fechaCreacion",
  },
  {
    label: "F. Previsión",
    name: "fechaPrevision",
  },
  {
    label: "Estado",
    name: "estado",
  },
];
export const METADATA_FONDO_RESERVA = [
  {
    label: "Tipo Fondo",
    name: "campo",
  },
  {
    label: "Fecha Modificación",
    name: "fecModificacion",
  },

  {
    label: "Valor",
    name: "valor",
  },
];

export const METADATA_MODAL_TAREA_ANALISIS_ACTIVOS = [
  {
    label: "Tipo Reclamación",
    name: "tipoReclamacion",
    type: "list",
    list: "TipoReclamacion",
  },
  {
    label: "Motivo",
    name: "tipoIncidencia",
    type: "list",
    list: "TipoIncidencia",
  },
];
export const METADATA_MODAL_TAREA_ANALISIS_FONDOS = [
  {
    label: "Tipo Fondo",
    name: "fondos",
    type: "list",
    list: "Fondos",
  },
  {
    label: "Importe",
    name: "importeFondo",
    type: "number",
  },
];
export const METADATA_MODAL_TAREA_ANALISIS_CANCELACION = [
  {
    label: "Seleccione el Activo",
    name: "idActivo",
    type: "list",
    list: "ActivosCancelacion",
  },
  {
    label: "Seleccione el tipo de Carga",
    name: "idCarga",
    type: "list",
    list: "TipoTareaCancelado",
  },
  {
    label: "Seleccione fecha previsión",
    name: "fechaPrevision",
    type: "date",
  },
  {
    label: "Seleccione el tipo de SubCarga",
    name: "idSubCarga",
    type: "list",
    list: "SubTipoTareaCancelado",
  },
];
export const METADATA_MODAL_TAREA_ANALISIS_TITULARIDAD = [
  {
    label: "Seleccione el Activo",
    name: "idActivo",
    type: "list",
    list: "ActivosTitularidad",
  },
  {
    label: "Seleccione la opcion de titularidad",
    name: "idCarga",
    type: "list",
    list: "TipoTareaTitularidad",
  },
  {
    label: "Seleccione fecha previsión",
    name: "fechaPrevision",
    type: "date",
  },
];

// CARGA
export const METADATA_CARGA_DATOS_GENERALES = [
  {
    label: "ID de la reserva",
    name: "codReserva",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Importe Total",
    name: "importeTotal",
  },
  {
    label: "Responsable formalización",
    name: "responsableFormalizacion",
  },
  {
    label: "Núm. activos conjunto",
    name: "numeroActivos",
  },
];
export const METADATA_CARGA_INFO_TAREA = [
  {
    label: "Tipo de Tarea",
    name: "tipoTarea",
  },
  {
    label: "Usuario Alta",
    name: "usuarioAlta",
  },
  {
    label: "Código Activo Prinex",
    name: "codigoActivoPrinex",
  },
  {
    label: "",
    name: "dummy1",
  },
  {
    label: "Fecha Inicio Tarea",
    name: "fechaInicioTarea",
  },
  {
    label: "Fecha Completada",
    name: "fechaCompletada",
  },
  {
    label: "Fecha Pausado",
    name: "fechaPausado",
  },
  {
    label: "Fecha Cancelación",
    name: "fechaCancelacion",
  },
];

// TAREA RECLAMACION
export const METADATA_SEARCH_RECLAMACION = [
  {
    label: "C. Activo",
    name: "codActivo",
    type: "text",
  },
  {
    label: "Promoción",
    name: "promocion",
    type: "text",
  },
  {
    label: "T. Inmueble",
    name: "tipoInmueble",
    type: "text",
  },
  {
    label: "C. Reserva",
    name: "codReserva",
    type: "text",
  },
  {
    label: "Responsable",
    name: "responsable",
    type: "text",
  },
  {
    label: "Motivo",
    name: "motivo",
    type: "text",
  },
  {
    label: "Finca",
    name: "finca",
    type: "text",
  },
  {
    label: "Registro",
    name: "registro",
    type: "text",
  },
  {
    label: "RefCatastral",
    name: "refCatastral",
    type: "text",
  },
  {
    label: "F. Prev. Firma",
    name: "fecPrevistaFirma",
    type: "text",
  },
  {
    label: "Est. Reclamación",
    name: "estadoReclamacion",
    type: "list-filter",
    list: "EstadosReclamacion",
  },
  {
    label: "F. Venta",
    name: "fecVenta",
    type: "text",
  },
];

export const METADATA_RESULT_RECLAMACION = [
  {
    label: "C. Activo",
    name: "codActivo",
    type: "text",
  },
  {
    label: "Promoción",
    name: "promocion",
    type: "text",
  },
  {
    label: "Negocio",
    name: "negocio",
    type: "text",
  },
  {
    label: "T. Inmueble",
    name: "tipoInmueble",
    type: "text",
  },
  {
    label: "C. Reserva",
    name: "codReserva",
    type: "text",
  },
  {
    label: "Responsable",
    name: "responsable",
    type: "text",
  },
  {
    label: "Motivo",
    name: "motivo",
    type: "text",
  },
  {
    label: "Finca",
    name: "finca",
    type: "text",
  },
  {
    label: "Registro",
    name: "registro",
    type: "text",
  },
  {
    label: "RefCatastral",
    name: "refCatastral",
    type: "text",
  },
  {
    label: "F. Prev. Firma",
    name: "fecPrevistaFirma",
    type: "text",
  },
  {
    label: "F. Previsión",
    name: "fecPrevisionV",
    type: "text",
  },
  {
    label: "Est. Reclamación",
    name: "estadoReclamacion",
    type: "list-filter",
    list: "EstadosReclamacion",
  },
  {
    label: "F. Venta",
    name: "fecVenta",
    type: "text",
  },
  {
    label: "Documento",
    name: "nombreDocumento",
    type: "text",
  },
];

// TAREA REVISIÓN OP
export const METADATA_REVISION_OP_DATOS_GENERALES = [
  {
    label: "ID de la Reserva",
    name: "codReserva",
  },
  { label: "VAI Colabora", name: "vaiColabora" },
  {
    label: "Nombre/Razón Social",
    name: "nombreRazonSocial",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Importe total",
    name: "importeTotal",
  },
  {
    label: "Importe total IBI",
    name: "importeTotalIBI",
  },
  {
    label: "Número de activos",
    name: "numeroActivos",
  },
  {
    label: "Venta Aplazada",
    name: "ventaAplazada",
  },
  {
    label: "Importe Aplazado",
    name: "importeAplazado",
  },
  {
    label: "Fecha notificación Tanteo CCAA",
    name: "fechaNotificacionTanteoCcaa",
  },
  {
    label: "Fecha recpción respuesta tanteo CCAA",
    name: "fechaRecepcionRespuestaTanteoCcaa",
  },
  { label: "Resultado Tanteo CCAA", name: "resultadoTanteoCcaa" },
  {
    label: "Fecha Notificación Tanteo Ayuntamiento",
    name: "fechaNotificacionTanteoAyuntamiento",
  },
  {
    label: "Fecha Recepción Respuesta Tanteo Ayuntamiento",
    name: "fechaRecepcionRespuestaTanteoAyuntamiento",
  },
  {
    label: "Resultado Tanteo Ayuntamiento",
    name: "resultadoTanteoAyuntamiento",
  },
  {
    label: "Gestión Escrituración",
    name: "gestEscr",
  },
  {
    label: "Campaña",
    name: "campana",
  },
];

export const METADATA_REVISION_OP_DOCS_ACTIVOS = [
  {
    label: "Tipo",
    name: "tipo",
  },
  {
    label: "Enlace",
    name: "nombreDocumento",
    type: "file",
  },
  {
    label: "F. recepción",
    name: "fechaRecepcion",
  },
];

export const METADATA_REVISION_OP_DATOS_FORMALIZACION = [
  {
    label: "Fecha prevista de firma",
    name: "fechaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Hora prevista",
    name: "horaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Nueva fecha/hora prevista",
    name: "nuevaFechaPrevistaFirma",
    type: "datetime-local",
  },
  {
    name: "dummy1",
    colSpan: "4 / 6",
    type: "readOnly",
  },
  {
    label: "Aplazamiento sin fecha",
    name: "aplazadoSinFecha",
    type: "checkbox",
  },
  {
    label: "Gestoría",
    name: "gestoria",
    type: "list",
    list: "Gestoria",
  },
  {
    label: "Provincia Notaría",
    name: "provincia",
    type: "readOnly",
    //list: "Provincia",
  },
  {
    label: "Notaría",
    name: "notario",
    type: "readOnly",
    //list: "Notario",
  },
  {
    label: "Tipo Financación",
    name: "tipoFinanciacion",
    type: "list",
    list: "TipoFinanciacion",
  },
  {
    label: "Importe Subrogación",
    name: "importeSubrogacion",
    type: "number",
    step: 100,
    min: 0,
  },
  {
    label: "Regimen fiscal",
    name: "regimenFiscal",
    type: "readOnly",
  },
  {
    label: "Gestoría confirma asistencia a firma",
    name: "gestoriaConfirmaAsistenciaFirma",
    type: "readOnly",
  },
  {
    label: "Vº Bº MinutaSSJJ",
    name: "vistoBuenoSSJJ",
    type: "readOnly",
  },
  {
    label: "Vº Bº Minuta",
    name: "vistoBuenoMinuta",
    type: "list",
    list: "VistoBuenoMinuta",
  },
  {
    label: "OK PBC",
    name: "okPBC",
    type: "readOnly",
  },
  {
    label: "Apoderamiento",
    name: "apoderamiento",
    type: "list",
    list: "Apoderamiento",
  },
  {
    name: "dummy2",
    colSpan: "6 / -1",
    type: "readOnly",
  },
  {
    label: "Sujeto Prorrateo",
    name: "sujetoProrrateo",
    type: "list",
    list: "ProrrateoIBI",
  },
  {
    label: "Repercusión importe",
    name: "repercusionImporte",
    type: "number",
    step: 100,
    min: 0,
  },
  {
    label: "Importe Teórico",
    name: "importeTeorico",
    type: "number",
    step: 100,
    min: 0,
  },
  {
    name: "dummy3",
    type: "readOnly",
  },
  {
    label: "Sujeto Campaña Comercial",
    name: "sujetocampanaComercial",
    type: "checkbox",
  },
  {
    label: "Importe Factura Pagado",
    name: "importeFacturaPagado",
    type: "number",
    step: 100,
    min: 0,
  },
  {
    colSpan: "1 / 4",
    label: "Comentario a la minuta",
    name: "comentarioVistoBuenoMinuta",
    type: "textarea",
    rows: 3,
  },
  {
    colSpan: "4 / 7",
    label: "Comentario Visto Bueno SSJJ",
    name: "comentarioVistoBuenoSSJJ",
    type: "textarea",
    rows: 3,
    disabled: true,
  },
  {
    label: "Fecha envio notaria",
    name: "fecEnvioNotaria",
    type: "readOnly",
  },
  {
    label: "Nueva Fecha envio notaria",
    name: "FechaEnvioNotaria",
    type: "datetime-local",
  },
  {
    label: "Fecha Recepción Borrador",
    name: "fecRecepcionBorrador",
    type: "readOnly",
  },
  {
    label: "Nueva Fecha Recepción Borrador",
    name: "FechaRecepcionBorrador",
    type: "datetime-local",
  },
  {
    name: "dummy4",
    colSpan: "6 / 7",
    type: "readOnly",
  },
  {
    name: "SeparadorAplazamiento",
    label: "Último aplazamiento",
    colSpan: "1 / 7",
    type: "separator",
  },

  {
    label: "Motivo Principal Aplazamiento",
    name: "motivoPrincipalAplazamiento",
    type: "readOnly",
  },
  {
    label: "Submotivo Aplazamiento",
    name: "submotivoAplazamiento",
    type: "readOnly",
  },
  {
    label: "Llegada de apoderado",
    name: "llegadaDeApoderado",
    type: "readOnly",
    list: "LlegadaDeApoderado",
  },
  {
    colSpan: "4 / 6",
    label: "Observaciones Aplazamiento Firma",
    name: "observacionesAplazamientoFirma",
    type: "textarea",
    rows: 3,
    disabled: true,
  },
  {
    label: "Número de aplazamientos",
    name: "numeroAplazamientos",
    type: "readonly",
  },
];

export const METADATA_OBSERVACIONES_ANALISIS_INMUEBLE = {
  colSpan: "1",
  label: "Observaciones Inmueble",
  name: "observacionesInmueble",
  type: "textarea",
  disabled: false,
  rows: 3,
};

export const METADATA_OBSERVACIONES_REVOP_FIRMA = {
  colSpan: "1",
  label: "Observaciones Firma",
  name: "observacionesFirma",
  type: "textarea",
  disabled: false,
  rows: 3,
};

export const METADATA_REVISION_OP_NOTAS_SIMPLES = [
  {
    label: "Nº PRINEX",
    name: "numeroPrinex",
  },
  {
    label: "R. Titularidad",
    name: "revisionTitularidad",
  },
  {
    label: "R. Cargas",
    name: "revisionCargas",
  },
  {
    label: "Res. Proceso Tit.",
    name: "resultadoTitularidad",
  },
  {
    label: "Res. Proceso Cargas",
    name: "resultadoCargas",
  },
];

export const MESSAGE_REVISION_OP_FISCALIDAD_COMPLETADA =
  "Ya se ha completado una tarea de solicitar fiscalidad para esta reserva. La fiscalidad es {1}.";
export const MESSAGE_REVISION_OP_FISCALIDAD_NO_COMPLETADA_INFORMADA =
  "La fiscalidad es {1}.";
export const MESSAGE_REVISION_OP_FISCALIDAD_NO_COMPLETADA_NO_INFORMADA = "";

// CONFIRMACION ASISTENCIA FIRMA
export const METADATA_CONFIRMACION_ASISTENCIA_FIRMA_DATOS_GENERALES = [
  {
    label: "ID de la Reserva",
    name: "codReserva",
  },
  {
    label: "Nombre/Razón Social",
    name: "nombreRazonSocial",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Importe total",
    name: "importeTotal",
  },
  {
    label: "Número de activos",
    name: "numeroActivos",
  },
];

export const METADATA_CONFIRMACION_ASISTENCIA_FIRMA_DATOS_FORMALIZACION = [
  {
    label: "Fecha prevista de firma",
    name: "fechaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Hora prevista",
    name: "horaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Gestoría",
    name: "gestoria",
    type: "readOnly",
  },
  {
    label: "Provincia Notaría",
    name: "provincia",
    type: "readOnly",
  },
  {
    label: "Notaría",
    name: "notario",
    type: "readOnly",
  },
  {
    label: "Confirmacion asistencia firma",
    name: "confirmacionAsistenciaFirma",
    type: "list",
    list: "ConfirmacionAsistenciaFirma",
  },
];

// TIPO FISCALIDAD
export const METADATA_TIPO_FISCALIDAD_DATOS_GENERALES = [
  {
    label: "ID de la Reserva",
    name: "codReserva",
  },
  { label: "VAI Colabora", name: "vaiColabora" },
  {
    label: "Nombre/Razón Social",
    name: "nombreRazonSocial",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Importe total",
    name: "importeTotal",
  },
];

export const METADATA_TIPO_FISCALIDAD_DATOS_FORMALIZACION = [
  {
    label: "Fecha prevista de firma",
    name: "fechaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Hora prevista",
    name: "horaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "¿Régimen fiscal?",
    name: "regimenFiscal",
    type: "list",
    list: "RegimenFiscal",
  },
  {
    label: "Observaciones",
    name: "observacionesFiscalidad",
    type: "text",
  },
];

export const METADATA_TIPO_FISCALIDAD_ACTIVOS = [
  {
    label: "Nº de PRINEX",
    name: "numeroPrinex",
  },
  {
    label: "Entidad",
    name: "sociedad",
  },
  {
    label: "Tipo",
    name: "tipo",
  },
  {
    label: "Finca",
    name: "finca",
  },
  {
    label: "ImpIBI",
    name: "importeIBI",
  },
  {
    label: "Registro",
    name: "registro",
  },
  {
    label: "Red Comercial",
    name: "redComercial",
  },
  {
    label: "Dirección",
    name: "direccion",
  },
  {
    label: "Provincia",
    name: "provincia",
  },
  {
    label: "Ref. Catastral",
    name: "referenciaCatastral",
  },
  {
    label: "Importe",
    name: "importeActivo",
  },
  {
    label: "ID Activo Prinex",
    name: "idActivoPrinex",
  },
  {
    label: "ID Activo Anticipa",
    name: "idActivoAnticipa",
  },
  {
    label: "ID Cliente",
    name: "idCliente",
  },
];

// VISTO BUENO SSJJ
export const METADATA_VISTO_BUENO_SSJJ_DATOS_GENERALES = [
  {
    label: "ID de la Reserva",
    name: "codReserva",
  },
  { label: "VAI Colabora", name: "vaiColabora" },
  {
    label: "Nombre/Razón Social",
    name: "nombreRazonSocial",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Importe total",
    name: "importeTotal",
  },
];

export const METADATA_VISTO_BUENO_SSJJ_DATOS_FORMALIZACION = [
  {
    label: "Fecha prevista de firma",
    name: "fechaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Hora prevista",
    name: "horaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Visto Bueno SSJJ",
    name: "vistoBuenoSSJJ",
    type: "list",
    list: "VistoBuenoSSJJ",
  },
  {
    label: "Comentarios SSJJ",
    name: "comentarioVistoBuenoSSJJ",
    type: "textarea",
  },
];

export const METADATA_VISTO_BUENO_SSJJ_MOTIVOS = [
  {
    label: "Motivo Solicitud",
    name: "motivo",
  },
  {
    label: "Tipo Motivo",
    name: "tipoMotivo",
  },
  {
    label: "Visto Bueno",
    name: "vistoBuenoSSJJ",
  },
  {
    label: "Comentarios",
    name: "comentSSJJ",
  },
  {
    label: "Fecha",
    name: "fecModificacion",
  },
];

export const METADATA_VISTO_BUENO_SSJJ_DOCS_ACTIVOS = [
  {
    label: "Tipo",
    name: "tipo",
  },
  {
    label: "Enlace",
    name: "nombreDocumento",
    type: "file",
  },
  {
    label: "F. recepción",
    name: "fechaRecepcion",
  },
  {
    label: "F. reclamación",
    name: "fechaReclamacion",
  },
  {
    label: "Motivo",
    name: "tipoIncidencia",
  },
];

// CONFIRMACION FIRMA
export const METADATA_CONFIRMACION_FIRMA_DATOS_GENERALES = [
  {
    label: "ID de la Reserva",
    name: "codReserva",
  },
  {
    label: "Nombre/Razón Social",
    name: "nombreRazonSocial",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Importe total",
    name: "importeTotal",
  },
  {
    label: "Número de activos",
    name: "numeroActivos",
  },
];

export const METADATA_CONFIRMACION_FIRMA_DATOS_FORMALIZACION = [
  {
    label: "Fecha prevista de firma",
    name: "fechaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Hora prevista",
    name: "horaPrevistaFirma",
    type: "readOnly",
  },
  {
    label: "Gestoría",
    name: "gestoria",
    type: "readOnly",
  },
  {
    label: "Provincia Notaría",
    name: "provincia",
    type: "readOnly",
  },
  {
    label: "Notaría",
    name: "notario",
    type: "readOnly",
  },
  {
    label: "Tipo Financiación",
    name: "tipoFinanciacion",
    type: "readOnly",
  },
  {
    label: "Regimen fiscal",
    name: "regimenFiscal",
    type: "readOnly",
  },
  {
    label: "OK PBC",
    name: "okPBC",
    type: "readOnly",
  },
  {
    label: "Visto bueno SSJJ",
    name: "vistoBuenoSSJJ",
    type: "readOnly",
  },
  {
    label: "Confirmación firma",
    name: "estadosFirma",
    type: "listEstadoFirma",
    list: "EstadosFirma",
  },
  {
    label: "Fecha Confirmacion Firma",
    name: "fechaConfirmacionFirma",
    type: "datetime-local",
  },
  {
    label: "Nueva Fecha Prevista Firma",
    name: "fecPrevistaFirma",
    type: "datetime-local",
  },
];

export const ESTADO_CONFIRMACION_FIRMA_CONFIRMADA = "44";
export const ESTADO_CONFIRMACION_FIRMA_APLAZADA_SIN_FECHA = "42";
export const ESTADO_CONFIRMACION_FIRMA_APLAZADA_CON_FECHA = "43";

// REPORTE GESTORIA
export const METADATA_REPORTE_GESTORIA_DATOS_GENERALES = [
  {
    label: "ID de la Reserva",
    name: "codReserva",
  },
  {
    label: "Nombre/Razón Social",
    name: "nombreRazonSocial",
  },
  {
    label: "Delegación territorial",
    name: "delegacionTerritorial",
  },
  {
    label: "Importe total",
    name: "importeTotal",
  },
];

export const METADATA_REPORTE_GESTORIA_DATOS_POST_FIRMA = [
  {
    label: "Fecha confirmación de firma",
    name: "fechaConfirmacionFirma",
    type: "readonly",
  },
  {
    label: "Firma en Hora",
    name: "firmaEnHora",
    type: "list",
    list: "FirmaEnHora",
  },
  {
    label: "Motivo",
    name: "motivo",
    type: "list",
    list: "MotivosReporteGestoria",
  },
  {
    label: "Protocolo de venta",
    name: "protocoloVenta",
    type: "number",
    min: 0,
  },
  {
    label: "Fecha envío cheque valija",
    name: "fechaEnvioChequeValija",
    type: "date",
  },
  {
    label: "Provincia Notaría",
    name: "provinciaNotario",
    type: "readOnly",
    //list: "Provincia",
  },
  {
    label: "Notaría",
    name: "notario",
    type: "readOnly",
    //list: "Notario",
  },
  {
    label: "Comentarios",
    name: "comentarios",
    type: "textarea",
  },
];

export const METADATA_MODULE_CHEQUES = [
  {
    label: "Tipo",
    name: "tipoDoc",
  },
  {
    label: "Enlace",
    name: "nombreDocumento",
    type: "file",
  },
  {
    label: "F. recepción",
    name: "fechaRecepcion",
  },
  {
    label: "Importe",
    name: "importe",
  },
  {
    label: "Nº Serie",
    name: "numeroSerie",
  },
  {
    label: "Sucursal",
    name: "sucursal",
  },
  {
    label: "Beneficiario",
    name: "beneficiario",
  },
];

export const METADATA_REPORTE_GESTORIA_DOCS_ACTIVOS = [
  {
    label: "Tipo",
    name: "tipo",
  },
  {
    label: "Enlace",
    name: "nombreDocumento",
    type: "file",
  },
  {
    label: "F. recepción",
    name: "fechaRecepcion",
  },
];

export const METADATA_MODAL_REPORTE_GESTORIA_CHEQUES = [
  {
    label: "Importe",
    name: "importe",
    type: "number",
    min: 0,
    step: 0.01,
  },
  {
    label: "Nº Serie",
    name: "serieCheque",
    type: "text",
  },
  {
    label: "Sucursal",
    name: "sucursal",
    type: "text",
  },
  {
    label: "Beneficiario",
    name: "beneficiario",
    type: "list",
    list: "Beneficiario",
  },
];

// AUTOTIZACION
export const METADATA_AUTORIZACION_DATOS_GENERALES = [
  {
    label: "ID de la reserva",
    name: "codReserva",
  },
  {
    label: "Usuario de la Tarea",
    name: "usuarioTarea",
  },
  {
    label: "Descripción de la Tarea",
    name: "descripcionTarea",
  },
  {
    label: "Estado de la Tarea",
    name: "estadoTarea",
  },
];

export const METADATA_AUTORIZACION_FORMULARIO = [
  {
    label: "Observacion",
    name: "observacion",
    type: "textarea",
  },
];

// FICHA RESERVA
export const METADATA_FICHA_RESERVA_DATOS_GENERALES = [
  { label: "ID de la Reserva", name: "codReserva" },
  { label: "VAI Colabora", name: "vaiColabora" },
  { label: "Oferta", name: "codOferta" },
  { label: "Nombre/Razón Social", name: "nombreRazonSocial" },
  { label: "DNI Titular", name: "dniTitular" },
  { label: "Importe total", name: "importeTotal" },
  { label: "Estado Comercial", name: "estadoComercial" },
  { label: "Fecha Creación E2E", name: "fecCreacionE2E" },
  { label: "Territorial", name: "territorial" },
  { label: "Delegación", name: "delegacion" },
  { label: "Zona", name: "zona" },
  { label: "¿OK PBC?", name: "okpbc" },
  { label: "Importe Reserva", name: "importeReserva" },
  { label: "Tipo de Venta", name: "tipoVenta" },
  { label: "Fecha prevista de firma", name: "fecPrevistaFirma" },
  { label: "Fecha confirmada de firma", name: "fecConfirmFirma" },
  {
    label: "Venta Aplazada",
    name: "ventaAplazada",
  },
  {
    label: "Importe Aplazado",
    name: "importeAplazado",
  },
  { label: "Nombre Api", name: "nombreApi" },
  { label: "Teléfono 1", name: "telefono1" },
  { label: "Teléfono 2", name: "telefono2" },
  { label: "Email", name: "email" },
  { label: "Gestión Escrituración", name: "gestEscr" },
  { label: "Campaña", name: "campana" },
];
export const DATA_SOLAPAS = [
  { name: "activos", label: "Activos" },
  { name: "docsReserva", label: "Doc. Reservas" },
  { name: "docsActivos", label: "Doc. Activos" },
  { name: "tareas", label: "Tareas" },
  { name: "cancelacionTitularidad", label: "Cancelación y Titularidad" },
  { name: "formalizacion", label: "Formalización" },
  { name: "emails", label: "Emails" },
  { name: "intervinientes", label: "Intervinientes" },
];
export const METADATA_SOLAPA_ACTIVOS = [
  { name: "promocion", label: "Promoción" },
  { name: "sociedad", label: "Entidad" },
  { name: "numeroPrinex", label: "N. Prinex" },
  { name: "negocio", label: "Negocio" },
  { name: "tipo", label: "Tipo" },
  { name: "finca", label: "Finca" },
  { name: "nRegistro", label: "NRegistro" },
  { name: "provincia", label: "Provincia" },
  { name: "registro", label: "Registro" },
  { name: "folio", label: "Folio" },
  { name: "libro", label: "Libro" },
  { name: "tomo", label: "Tomo" },
  { name: "idufir", label: "IDUFIR" },
  { name: "referenciaCatastral", label: "Ref. Catastral" },
  { name: "importeActivo", label: "Importe Activo" },
  { name: "importeIBI", label: "ImpIBI" },
  { name: "regFiscal", label: "Reg. Fiscal" },
  { name: "inmSGA", label: "Inm. SGA" },
  { name: "fecSGA", label: "Fecha SGA" },
  { name: "inmInscrito", label: "Inm. Inscrito" },
  { name: "fecInscrito", label: "Fecha Inscripcion Traspaso" },
  { name: "eAlquiler", label: "E. Alquiler" },
  { name: "eTecCom", label: "E. Tec. Com" },
  { name: "sum", label: "Sum" },
  { name: "seg", label: "Seg" },
  { name: "idActivoPrinex", label: "ID Prinex" },
  { name: "idActivoAnticipa", label: "ID Anticipa" },
  { name: "idCliente", label: "ID Cliente" },
];
export const METADATA_SOLAPA_TAREAS = [
  { name: "prioridad", label: "Prioridad" },
  { name: "tarea", label: "Tarea" },
  { name: "estado", label: "Estado" },
  { name: "usuario", label: "Usuario" },
  { name: "fAsignacion", label: "F. Asignación" },
  { name: "fCompletado", label: "F. Completado" },
];
export const METADATA_SOLAPA_CANCELACION_TITULARIDAD = [
  { name: "codPrinex", label: "Cód. Prinex" },
  { name: "tarea", label: "Tarea" },
  { name: "estado", label: "Estado" },
  { name: "usuarioCreador", label: "Usuario Creador" },
  { name: "fechaInicio", label: "Fecha Inicio" },
  { name: "fechaCompletado", label: "Fecha Completado" },
  { name: "fechaCancelado", label: "Fecha Cancelado" },
  { name: "mirarCarga", label: "", type: "clickable" },
];
export const METADATA_SOLAPA_FORMALIZACION_GENERALES = [
  { label: "Fecha prevista de firma", name: "fechaPrevistaFirma" },
  { label: "Hora prevista", name: "horaPrevistaFirma" },
  { label: "Notaría", name: "notaria" },
  { label: "Provincia Notaría", name: "provinciaNotaria" },
  { label: "Datos Contacto CGOA", name: "datosContactoCGOA" },
  { label: "Gestoría", name: "gestoria" },
  { label: "Responsable Formalización", name: "responsableFormalizacion" },
  { label: "Apoderamiento", name: "apoderamiento" },
  { label: "Tipo Financiación", name: "tipoFinanciacion" },
  { label: "Importe Subrogación", name: "importeSubrogacion" },
  { label: "Regimen fiscal", name: "regimenFiscal" },
  { label: "¿Permiso especial de venta?", name: "permisoEspecialVenta" },
  {
    label: "Usuario Inicio manual Formalización",
    name: "usuarioInicioManualFormalizacion",
  },
  {
    label: "Visto Bueno SSJJ",
    name: "vistoBuenoSSJJ",
  },
];
export const METADATA_SOLAPA_FORMALIZACION_GESTORIA = [
  { name: "confirmacionAsistenciaFirma", label: "Conf. asistencia a firma" },
  { name: "firmaEnHora", label: "¿Firma en hora?" },
  { name: "motivoRetrasoFirma", label: "Motivo Retraso en Firma" },
  {
    name: "motivosRetrasoFirma",
    label: "Motivos Retraso Firma",
    type: "textarea",
    colSpan: "1 / -1",
  },
];
export const METADATA_SOLAPA_FORMALIZACION_FIRMA = [
  { name: "confFirma", label: "Conf. firma" },
  { name: "fecFirmaConfirmada", label: "Fecha Firma Confirmada" },
  { name: "incidenciasEnFirma", label: "¿Incidencias en Firma?" },
];
export const METADATA_SOLAPA_FORMALIZACION_VISTO_BUENO = [
  { name: "vistoBueno", label: "Visto bueno" },
  {
    name: "comentariosMinuta",
    label: "Comentarios a la minuta",
    type: "textarea",
  },
];
export const METADATA_SOLAPA_FORMALIZACION_HISTORICO = [
  { name: "motivoSolicitud", label: "Motivo solicitud" },
  { name: "vistoBuenoSSJJ", label: "VB" },
  { name: "comentarios", label: "Comentarios" },
  { name: "fecha", label: "Fecha" },
];
export const METADATA_SOLAPA_FORMALIZACION_FISCALIDAD = [
  { name: "observaciones", label: "Observaciones", type: "textarea" },
];
export const METADATA_SOLAPA_EMAILS = [
  { name: "asunto", label: "Asunto" },
  { name: "destinatario", label: "Destinatario" },
  { name: "remitente", label: "Remitente" },
  { name: "fecha", label: "Fecha" },
  { name: "email", label: "E-mail", type: "clickable" },
];
export const METADATA_SOLAPA_INTERVINIENTES = [
  { name: "tipoInterviniente", label: "Tipo Interviniente" },
  { name: "nifcif", label: "NIF/CIF" },
  { name: "nombreRazonSocial", label: "Nombre/Razón Social" },
  { name: "tipoPersona", label: "Tipo Persona" },
];

// PALETA DE COLORES
export const COLORES = {
  // LOADING
  LOADING_BACKGROUND: "#5558",
  // MENU
  MENU_FONT: "white",
  MENU_BACKGROUND: "#e00e49",
  MENU_BACKGROUND_ENV: REACT_APP_BAR_COLOR,
  MENU_HOVER_BACKGROUND: "#dd6666",
  MENU_USER_FONT: "black",
  MENU_USER_BACKGROUND: "white",
  MENU_USER_BORDER: "lightgrey",
  MENU_USER_HOVER: "#f99",
  MENU_USER_ICON: "#e00e49",
  // DEFAULT MODULE
  DEFAULT_MODULE_HEADER_FONT: "white",
  DEFAULT_MODULE_HEADER_BACKGROUND: "#e00e49",
  DEFAULT_MODULE_BACKGROUND: "#eee",
  // LISTS
  LIST_ACTIVE_BACKGROUND: "#e00e49",
  LIST_HOVER_BACKGROUND: "#f99",
  LIST_LIGHT_BORDER: "#858585",
  LIST_BORDER: "#767676",
  MULTISELECT_LIGHT_BORDER: "#858585",
  MULTISELECT_LIST_ACTIVE: "#767676",
  MULTISELECT_BORDER: "#767676",
  MULTISELECT_BACKGROUND: "white",
  MULTISELECT_OPTION_HOVER: "#f99",
  // RESULT MODULE
  RESULT_MODULE_HEADER_FONT: "white",
  RESULT_MODULE_HEADER_FONT_HOVER: "black",
  RESULT_MODULE_HEADER_FONT_DISABLED: "lightgrey",
  RESULT_MODULE_HEADER_BACKGROUND: "#e00e49",
  RESULT_MODULE_BACKGROUND: "#eee",
  EXTRA_HEADER_FONT: "white",
  EXTRA_HEADER_HOVER: "black",
  EXTRA_HEADER_DISABLED: "lightgrey",
  EXTRA_HEADER_BACKGROUND: "darkgrey",
  SORT_BUTTON_HOVER: "grey",
  SORT_BUTTON_ACTIVE: "darkgrey",
  SORT_BUTTON_BACKGROUND: "transparent",
  HEADER_ROW_BACKGROUND: "lightgrey",
  ROW_HOVER: "#f8dddd",
  ACTIONS_BUTTON_FONT: "black",
  ACTIONS_BUTTON_FONT_HOVER: "black",
  ACTIONS_BUTTON_BACKGROUND_HOVER: "lightgrey",
  // BUTTONS
  BUTTON_FONT: "white",
  BUTTON_BACKGROUND: "#e00e49",
  BUTTON_CANCEL_FONT: "#e00e49",
  BUTTON_CANCEL_BACKGROUND: "white",
  BUTTON_DENY_FONT: "#e00e49",
  BUTTON_DENY_BACKGROUND: "white",
  BUTTON_SHADOW: "grey",
  BUTTON_BACKGROUND_DISABLED: "lightgrey",
  FONT_AWESOME_ICON_DISABLED: "darkgrey",
  // ACTIVE DOCUMENTS
  ACTIVE_HEADER_FONT: "white",
  ACTIVE_BACKGROUND: "#eee",
  ACTIVE_HEADER_BACKGROUND: "#ddd",
  ACTIVE_NEW_ICON: "#e00e49",
  ACTIVE_DOC_LOCK_ICON: "darkred",
  ACTIVE_NARANJAFASTRACK: "#FFB776",
  NARANJA_FASTRACK: "#FEA658",
  // MODAL
  MODAL_HEADER_FONT: "white",
  MODAL_HEADER_BACKGROUND: "#e00e49",
  MODAL_BACKGROUND: "white",
  MODAL_UPLOAD_ICON: "#e00e49",
  // INDICATOR STATUS
  STATUS: {
    OK: "green",
    WARN: "yellow",
    FAIL: "red",
  },
  STATUS_DEFAULT: "lightgrey",
  // INDICATOR ESTADO CARGA
  STATUS_CARGA: {
    ACTIVE_FILL: "orange",
    NO_ACTIVE_FILL: "#ffe1c0",
    ACTIVE_FONT: "white",
    NO_ACTIVE_FONT: "orange",
    BORDER: "orange",
  },
  //
  UPLOAD_ICON: "#555",
  DOWNLOAD_FONT: "red",
};