import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileLines,
  faUserPlus,
  faUsers,
  faServer,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";

export const RowResultTareas = ({
  metadata,
  data,
  selected,
  handleChangeSelection,
  requestAsignTarea,
  requestReAbrirTarea,
  requestReasignTarea,
}) => {
  return (
    <S.Tr>
      <S.TdCheck>
        {(data.acciones.esAsignable || data.acciones.reAsignable) && (
          <input
            type="checkbox"
            id={data.tarea?.valor}
            checked={selected}
            onChange={handleChangeSelection}
          />
        )}
      </S.TdCheck>
      <S.TdButtons>
        <a
          href={
            data.acciones?.modulo ? `/FichaReserva/${data.reserva?.valor}` : "/"
          }
          target="_blank"
          rel="noreferrer"
        >
          <button>
            <FontAwesomeIcon icon={faFileLines} />
          </button>
        </a>
        {data.acciones.esAsignable && (
          <button onClick={() => requestAsignTarea(data.tarea.valor)}>
            <FontAwesomeIcon icon={faUserPlus} />
          </button>
        )}
        {data.acciones.puedeReAbrir && (
          <button onClick={() => requestReAbrirTarea(data.tarea.valor)}>
            <FontAwesomeIcon icon={faRecycle} />
          </button>
        )}
        {data.acciones.reAsignable && (
          <button onClick={() => requestReasignTarea(data.tarea.valor)}>
            <FontAwesomeIcon icon={faUsers} />
          </button>
        )}
        {data.acciones.puedeAbrir && (
          <a
            href={
              data.acciones?.modulo
                ? `/${data.acciones?.modulo}/${data.tarea?.valor}`
                : "/"
            }
            target="_blank"
            rel="noreferrer"
          >
            <button>
              <FontAwesomeIcon icon={faServer} />
            </button>
          </a>
        )}
        {API.DEVELOP && ( ///temp
          <a
            href={
              data.acciones?.modulo
                ? `/${data.acciones?.modulo}/${data.tarea?.valor}`
                : "/"
            }
            target="_blank"
            rel="noreferrer"
          >
            <button style={{ color: "lightgrey" }}>
              <FontAwesomeIcon icon={faServer} />
            </button>
          </a>
        )}
      </S.TdButtons>
      {metadata.map((e) => {
        return e.name === "notificaciones" ? (
          <S.circulo key={e.name}>
            <span className={"prioridad" + data.colorNotificaciones}>
              {data[e.name]?.etiqueta || data[e.name]}
            </span>
          </S.circulo>
        ) : (
          <td key={e.name}>{data[e.name]?.etiqueta || data[e.name]}</td>
        );
      })}
  
    </S.Tr>
  );
};

RowResultTareas.propTypes = {
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  handleChangeSelection: PropTypes.func,
  requestAsignTarea: PropTypes.func,
  requestReAbrirTarea: PropTypes.func,
  requestReasignTarea: PropTypes.func,
};
