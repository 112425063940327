import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";

export const RowResultReservas = ({ metadata, data }) => {
  return (
    <S.Tr>
      <S.TdButtons>
        <a
          href={data.reserva ? `/FichaReserva/${data.reserva.valor}` : null}
          target="_blank"
          rel="noreferrer"
        >
          <button>
            <FontAwesomeIcon icon={faFileLines} />
          </button>
        </a>
      </S.TdButtons>
      {metadata.map((e) => {
        return <td key={e.name}>{data[e.name]?.etiqueta || data[e.name]}</td>;
      })}
    </S.Tr>
  );
};

RowResultReservas.propTypes = {
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};
